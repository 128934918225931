import {useMutation, useQuery} from "react-query";
import {toast} from "react-toastify";
import Loader from "../commons/loader";
import {useState} from "react";
import { useSearchParams } from "react-router-dom";
import { APP_URL } from "../../config";
import { CircularProgress } from "@mui/material";
import axios from "axios";

const EditProfile = async (member) => {
    try{
        const res = fetch(`${APP_URL}/user/` + member.id, {
            method: "PUT",
            body: JSON.stringify(
              member
            )});

        if (!res.ok){
            throw new Error("An error occurred while editing profile")
        }
        const data = await res.json();
        return data;
    } catch (e) {
        throw new Error(e)
    }
}

const Profile = ({user})=>{

    const [params] = useSearchParams()

    const [profileForm, setProfileForm] = useState({
        id: user?._id,
        fname: user?.fname,
        lname: user?.lname,
        email: user?.email,
        phone: user?.phone,
        password: '',
        
    })


    const handleChange = (event) => {
        const { name, value, type, files } = event.target;

        // Update the form data based on the input type
        setProfileForm((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');
    const [message, setMessage] = useState('');
    
    const handleSubmit = async () => {
        setMessage('')
        setError("")
        if (!profileForm.fname || !profileForm.lname || !profileForm.email || !profileForm.phone || !profileForm.password ) {
          setError("Please fill all the fields");
          return;
        }
      
        try {
          setLoading(true);
          const res = await axios.put(`${APP_URL}/user/${profileForm.id}`, profileForm, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
      
          if (!res.data.msg) {
            throw new Error("An error occurred while editing profile");
            return
          }
      
          // Update the local profileForm state with the new data
          setError(null);
          setMessage("Successfuly updated !!!")
        } catch (e) {
          setError("An error occurred while updating profile");
        } finally {
          setLoading(false);
        }
      };


    return (
        <section className="flex flex-col items-center gap-20 w-[90%] pb-10" >

            <div className="w-full flex flex-col gap-5 items-center">
                <h1 className="text-5xl pb-4 border-b-2 w-fit">
                    Profile
                </h1>
              
            </div>

            <div
                className="grid place-items-center w-full gap-10"
            >
              <form className="w-full max-w-[600px] shadow-lg shadow-form p-10 flex flex-col gap-5 rounded-md">

                  <div className="flex flex-col items-start w-full">
                      <label>
                          First Name
                      </label>
                      <input
                        value={profileForm.fname}
                        onChange={handleChange}
                        name="fname" type="text" className="p-2 rounded-md w-full border-input border-2" />
                  </div>

                  <div className="flex flex-col items-start w-full">
                      <label>
                          Last Name
                      </label>
                      <input
                        value={profileForm.lname}
                        onChange={handleChange}
                        name="lname" type="text" className="p-2 rounded-md w-full border-input border-2" />
                  </div>

                  <div className="flex flex-col items-start w-full">
                      <label>
                          Email
                      </label>
                      <input
                        value={profileForm.email}
                        onChange={handleChange}
                        name="email" type="email" className="p-2 rounded-md w-full border-input border-2" />
                  </div>

                  <div className="flex flex-col items-start w-full">
                      <label>
                          Phone Number
                      </label>
                      <input
                        value={profileForm.phone}
                        onChange={handleChange}
                        name="phone" type="text" className="p-2 rounded-md w-full border-input border-2" />
                  </div>

                  <div className="flex flex-col items-start w-full">
                      <label>
                          Password
                      </label>
                      <input
                        value={profileForm.password}
                        onChange={handleChange}
                        name="password" type="password" className="p-2 rounded-md w-full border-input border-2" />
                  </div>

                {
                    loading?
                      <div style={{textAlign:"center"}}><CircularProgress /></div>
                    :
                  <button
                    onClick={(e)=>{
                      e.preventDefault();
                      handleSubmit();
                    }}
                      className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center">
                      
                      Save
                  </button>

                }
                  {error && <div style={{textAlign:"center"}} className="text-red-500">{error}</div>}
                  {message && <div style={{textAlign:"center"}} className="text-green-500">{message}</div>}

              </form>

            </div>

        </section>
    )
}

export default Profile;