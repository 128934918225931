import React from "react";
import { Link } from "react-router-dom";
import { MdCardMembership } from "react-icons/md";
import { MdOutlineUnsubscribe } from "react-icons/md";
import { MdEvent } from "react-icons/md";
import { GrGallery } from "react-icons/gr";
import { FaBlog } from "react-icons/fa";
import { MdOutlineEventRepeat } from "react-icons/md";
import { FaMailBulk } from "react-icons/fa";
import { BiMessageRoundedDots } from "react-icons/bi";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Typography } from "@mui/material";

function AdminPanel() {
  return (
    <>
      <Typography variant="h4" style={{textAlign:"center",fontWeight:700}}>Admin Panel</Typography>
        <div className="border-t m-4 min-h-[70vh]" style={{width:"70%"}}>
            <div className="flex flex-col justify-start gap-6 mt-20 mb-40" >
            <Link to="/profile" className="flex justify-start items-center gap-4 hover:scale-105 transition-transform">
                <div className="text-5xl text-green-500">
                <AccountCircleIcon style={{ fontSize: "50px" }} />
                </div>
                <h2 className="text-xl font-medium">My Profile</h2>
            </Link>
        
            <Link to="/displayMembers" className="flex justify-start items-center gap-4 hover:scale-105 transition-transform">
                <div className="text-5xl text-green-500">
                <MdCardMembership />
                </div>
                <h2 className="text-xl font-medium">Members</h2>
            </Link>
        
            <Link to="/displaySubscribe" className="flex justify-start items-center gap-4 hover:scale-105 transition-transform">
                <div className="text-5xl text-green-500">
                <MdOutlineUnsubscribe />
                </div>
                <h2 className="text-xl font-medium">Subscriber</h2>
            </Link>
        
            <Link to="/displayEvent" className="flex justify-start items-center gap-4 hover:scale-105 transition-transform">
                <div className="text-5xl text-green-500">
                <MdEvent />
                </div>
                <h2 className="text-xl font-medium">Event Management</h2>
            </Link>
        
            <Link to="/displayGallery" className="flex justify-start items-center gap-4 hover:scale-105 transition-transform">
                <div className="text-5xl text-green-500">
                <GrGallery />
                </div>
                <h2 className="text-xl font-medium">Gallery Management</h2>
            </Link>
        
            <Link to="/displayBlog" className="flex items-center gap-4 hover:scale-105 transition-transform">
                <div className="text-5xl text-green-500">
                <FaBlog />
                </div>
                <h2 className="text-xl font-medium">Blog Management</h2>
            </Link>
        
            {/* <Link to="/displayRegisteredEvent" className="flex items-center gap-4 hover:scale-105 transition-transform">
                <div className="text-5xl text-green-500">
                <MdOutlineEventRepeat />
                </div>
                <h2 className="text-xl font-medium">posted Events Management</h2>
            </Link> */}
        
            <Link to="/createEmail" className="flex items-center gap-4 hover:scale-105 transition-transform">
                <div className="text-5xl text-green-500">
                <FaMailBulk />
                </div>
                <h2 className="text-xl font-medium">Email Management</h2>
            </Link>
        
            <Link to="/displayMessage" className="flex items-center justify-first gap-4 hover:scale-105 transition-transform">
                <div className="text-5xl text-green-500">
                <BiMessageRoundedDots />
                </div>
                <h2 className="text-xl font-medium">Messages</h2>
            </Link>
            </div>
    </div>
    </>
  );
}

export default AdminPanel;
