import axios from 'axios';
import { useState } from 'react';
import { APP_URL } from '../../config';
import { CircularProgress } from '@mui/material';

const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true)
    setError('')
    try {
      // Validate email and password
      if (!formData.email || !formData.password) {
        setError('Please enter your email and password.');
        setLoading(false)
        return;
      }


  
      const response = await axios.post(`${APP_URL}/user/login`, formData,{
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const { data } = response.data;
  
      // Store the token in local storage
      setLoading(false)
      localStorage.setItem('token', data.token);
      // Redirect to the home page
        window.location.href = '/';
    } catch (error) {
     
        setLoading(false)
        if (error.response) {
        setError(error.response?.data?.error);
      } else if (error.request) {
        setError('Network error. Please check your internet connection and try again.');
      } else {
        setError('An unexpected error occurred. Please try again later.');
      }
    }
  };

  return (
    <section className="grid place-items-center w-full h-[90vh]">
      <form className="w-full max-w-[500px] shadow-lg shadow-form p-10 flex flex-col gap-5 rounded-md items-center">
        <h1 className="text-3xl pb-4 border-b-2">Login Page</h1>

        <div className="flex flex-col items-start w-full">
          <label>Email</label>
          <input
            name="email"
            value={formData.email}
            onChange={handleChange}
            type="email"
            className="p-2 rounded-md w-full border-input border-2"
          />
        </div>

        <div className="flex flex-col items-start w-full">
          <label>Password</label>
          <input
            name="password"
            value={formData.password}
            onChange={handleChange}
            type="password"
            className="p-2 rounded-md w-full border-input border-2"
          />
        </div>

        {loading?
         <CircularProgress />
        :
        
        <button
          onClick={handleLogin}
          className="bg-green-500 w-fit hover:bg-green-700 text-white font-bold py-2 px-6 rounded"
        >
          Login
        </button>
        }
        {error && <div className="text-red-500">{error}</div>}

      </form>
    </section>
  );
};

export default LoginPage;