import hero from "../../assets/header-bg.png";
import logo from "../../assets/plogging-logo.png";
import headerLeft from "../../assets/header-left.png";
import { Link } from "react-router-dom"

const Hero = () => {
  return (
    // <div class="relative bg-gradient-to-r from-purple-600 to-blue-600 h-screen text-white overflow-hidden w-full">
    //   <div class="absolute inset-0">
    //     <img src={hero} alt="Background Image" class="object-cover object-center w-full h-full" />
    //     <div class="absolute inset-0 bg-black opacity-50"></div>
    //   </div>
      
    //   <div class="relative z-10 flex flex-col justify-center items-center h-full text-center">
    //     <h1 class=" text-3xl md:text-5xl font-bold leading-tight mb-4">Welcome to Plogging Ethiopia</h1>
    //     <p class="text-lg font-sans font-black text-gray-300 mb-8">Stride with purpose, and cleanse with passion!</p>
    //     <Link href="#" class="bg-green-600 text-gray-900 hover:bg-green-500 py-2 px-6 rounded-full text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">Read More </Link>
    //  </div>  
    // </div>
      <div data-aos="fade-up" class="grid md:grid-cols-2 w-full text-white font-Poppins py-10 pl-5 aos-init aos-animate" style={{backgroundImage: `url(${hero})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
             <div class="flex flex-col items-center justify-around w-full gap-10">
                  <h1 class="md:text-7xl text-4xl font-light text-left text-wrap">Welcome to Plogging Ethiopia</h1>
                  <p class="italic text-2xl">Stride with purpose, and cleanse with passion!</p>
                  <div class="flex items-center justify-between">
                      <button class="bg-green-500 hover:bg-green-700 text-white h-fit font-light py-2 px-4 rounded self-start">
                        <a href="/#aboutus">Read More +</a>
                      </button>
                      <img class="w-1/2 rounded-full pl-2 pt-1 bg-green-500" alt="Plogging Ethiopia" src={logo}/>
                  </div>

              </div>
              <img src={headerLeft}/>
      </div>
  
  )
}


export default Hero;
