import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { APP_URL, APP_URL_2 } from '../../../config';
import { FaArrowLeft } from "react-icons/fa";
import { Typography } from '@mui/material';

const ViewMessage = () => {
  const { messageId } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${APP_URL}/message/${messageId}`);
        const message = response.data;
        setName(message.name);
        setEmail(message.email);
        setMessage(message.message);
      } catch (error) {
        //console.error('Error fetching event:', error);
      }
    };

    fetchEvent();
  }, [messageId]);

  return (
    <>
     <Typography variant="h4" style={{textAlign:"center",fontWeight:700,marginBottom:"30px"}}>View Messages</Typography>
      <form  className='w-100 shadow-lg bg-slate-50 p-6 ' style={{marginBottom:"60px"}}>
        <Link to='/displayMessage' className='m-4'>
          <button type="button" className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
            <FaArrowLeft />
          </button>
        </Link>
        <div className="m-4">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

              <div className="sm:col-span-4">
                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                <div className="mt-2" style={{paddingLeft:'10px',lineBreak:"anywhere",wordBreak:"break-all"}}>
                    {name}
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">email</label>
                <div className="mt-2" style={{paddingLeft:'10px',lineBreak:"anywhere",wordBreak:"break-all"}}>
                    {email}
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">Description</label>
                <div className="mt-2" style={{paddingLeft:'10px',lineBreak:"anywhere",wordBreak:"break-all"}}>
                    {message}
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </>
  );
};

export default ViewMessage;
