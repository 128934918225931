import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { APP_URL, APP_URL_2 } from '../../../config';
import Loader from '../../commons/loader';
import { FaArrowLeft } from 'react-icons/fa';
import { useMediaQuery } from '@mui/material';
import styled from "styled-components";
import moment from "moment";


function EventDetail() {
  const { eventId } = useParams();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [user, setUser] = useState(null); 
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Function to fetch logged-in user data
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const response = await axios.get(`${APP_URL}/user/getUser`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUser(response.data.data);
          setUserId(response.data.data._id); // Set the userId state
     
        }
      } catch (error) {
        //console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, []); 



  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${APP_URL}/event/${eventId}`);
        const event = response.data;
        setTitle(event.title);
        setDescription(event.description);
        setDate(event.date);
        setImages(event.images[0]);
        const filePreviews = event.images.map((img) => {
          img = img.replace('/uploads/events/', '');
          return `${APP_URL_2}/events/${img}`;
        });
        setImagePreviews(filePreviews);
        setIsLoading(false);
      } catch (error) {
        //console.error('Error fetching event:', error);
      }
    };

    fetchEvent();
  }, [eventId]);

  const handleRegister = async () => {
    if (!user) {
      navigate('/login'); // Redirect to login page if not logged in
      return;
    }

    try {
      const response = await axios.post(`${APP_URL}/registerForEvent`, {
        user_id: userId,
        event_id: eventId,
      });
      if (response.status === 201) {
        navigate('/'); // Redirect to home page if successful
      } else {
        // Handle error, e.g., display an error message
        //console.error('Error registering for event:', response.data.message);
      }
    } catch (error) {
      //console.error('Error registering for event:', error);
    }
  };

  const matches = useMediaQuery('(max-width:800px)');

  if(isLoading){
    return(
      <div style={{height:"60vh",display:"flex",justifyContent:"center",alignItems:"center",}}> <Loader /></div>
    )
  }

  
  
  return (
    <body className="font-poppins relative min-h-[70vh]">
       <Link to='/event' className='m-4' style={{marginBottom:"20px"}}>
          <button type="button" className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
            <FaArrowLeft />
          </button>
        </Link>
      <div id="container" className="mb-6" style={{width:"100%"}} >
        
        <div
          id="container"
          className="flex justify-start flex-col items-center mb-6"
          style={{lineBreak:"anywhere",width:"100%"}}
        >
          <Div >
            {imagePreviews[0] && (
              <Img
                src={imagePreviews[0]}
                alt="Event Image"
                className="w-full max-h-100 mb-8"
                style={{

                }}
                loading='lazy'
              />
            )}
          </Div>

          <Div2 style={{lineBreak:"anywhere",padding:"15px"}}>
            <h1 className="text-black font-bold text-3xl mt-6 mb-8">{title} </h1>
            <div style={{wordBreak:"break-word",lineBreak:"anywhere"}} className='detail-description-styling'>
               <div dangerouslySetInnerHTML={{ __html: description }} />
           </div>
           <div style={{marginTop:"20px",fontSize:"12px",opacity:0.8,width:"100%",textAlign:"end"}}> {moment(date).format('LL')} </div>

            {/* <div className="flex justify-center">
              <button
                type="button"
                className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-32 py-2.5 text-center me-2 mb-2"
              >
                Register
              </button>
            </div> */}
          </Div2>
        </div>
      </div>
     
    </body>
  );
}


const Div2 = styled.div`
width: 600px;
@media (max-width: 800px) {
  width: 450px;
};
@media (max-width: 600px){
  width:350px;
 };
 @media (max-width: 500px){
  width:90%;
 };
`;

const Div = styled.div`
  width: 600px;
  @media (max-width: 800px) {
    width: 450px;
  }
  @media (max-width: 600px) {
    width: 350px;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  max-height: 550px;
  object-fit: contain;
  @media (max-width: 800px) {
    max-height: 400px;
  }
  @media (max-width: 600px) {
    max-height: 300px;
  }
  @media (max-width: 500px) {
    object-fit: contain;
  }
`;

export default EventDetail;