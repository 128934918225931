import "./loader.css";
const Loader = () => {
  return (
    <div className="loadingio-spinner-rolling-xufku1drzpe">
      <div className="ldio-arj2khm927b">
        <div></div>
      </div>
    </div>
  )
}

export default Loader;