//  export const APP_URL = 'https://api.ploggingethiopia.org/api';
//  export const APP_URL_2 = 'https://api.ploggingethiopia.org';

 export const APP_URL = 'https://server.ploggingethiopia.org/api';
 export const APP_URL_2 = 'https://server.ploggingethiopia.org';

// export const APP_URL = 'http://localhost:5000/api';
// export const APP_URL_2 = 'http://localhost:5000';



    